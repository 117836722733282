import React from "react"
import { useTranslation } from "react-i18next"
import "./WinbackFallbackPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import ErrorTitleText from "../components/text/ErrorTitleText"
import FormFooter from "../components/form/footer/FormFooter"
import { getQueryValueFromCurrentUrl } from "../util/urlUtil"
import StandardButton from "../components/buttons/StandardButton"
import TitleText from "../components/text/TitleText"
import CheckmarkIcon from "../images/icons/green/checkmark.png"

interface IWinbackFallbackPageProps {}

const WinbackFallbackPage = ({}: IWinbackFallbackPageProps) => {
    const { t } = useTranslation()
    const originUrl = decodeURIComponent(getQueryValueFromCurrentUrl("originUrl"))
    const shopName = decodeURIComponent(getQueryValueFromCurrentUrl("shopName"))

    return (
        <PageWrapper narrow>
            <div id="winback-fallback-page">
                <TitleText title={t("winbackFallbackPage.title")} text="" />
                <section className="flex-stacked">
                    <img id="checkmark" src={CheckmarkIcon} />
                    <div>
                        <p className="content-text">
                            {t("winbackFallbackPage.text1")} <b>{shopName}.</b>
                        </p>
                        <p className="content-text">{t("winbackFallbackPage.text2")}</p>
                    </div>
                </section>
                <StandardButton
                    dark
                    isLarge
                    noBorder
                    noBorderRadius
                    className="w-100 mt-3"
                    onClick={async () => {
                        document.location.href = originUrl
                    }}
                >
                    {t("common.continue")}
                </StandardButton>
            </div>
            <FormFooter />
        </PageWrapper>
    )
}

export default WinbackFallbackPage
