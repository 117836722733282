import React from "react"
import { useTranslation } from "react-i18next"
import { TPaymentType } from "../../../type/paymentPage/IPaymentPageData"
import useReduxDispatch from "../../../store/useReduxDispatch"
import { useSelector } from "react-redux"
import { paymentPageReducerActions, paymentPageSelectors } from "../../../store/reducers/paymentPageReducer"
import "./ChangePaymentMethodButton.scss"

interface IChangePaymentMethodButtonProps {
    paymentType: TPaymentType
}
const ChangePaymentMethodButton = ({ paymentType }: IChangePaymentMethodButtonProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const selectedPaymentType = useSelector(paymentPageSelectors.selectedPaymentType)
    const paymentPageData = useSelector(paymentPageSelectors.data)

    if (selectedPaymentType !== paymentType) {
        return null
    }

    if (!paymentPageData?.showAlternativePaymentMethods) {
        return null
    }

    return (
        <button
            className="change-method-button noselect"
            onClick={() => dispatch(paymentPageReducerActions.setSelectedPaymentType(undefined))}
        >
            {t("paymentpage.changeMethod")}
        </button>
    )
}

export default ChangePaymentMethodButton
