import React from "react"
import { IPaymentLinkSupportedPaymentType } from "../../type/paymentPage/IPaymentPageData"
import "./CreditCardPaymentTypeIcon.scss"
import { useTranslation } from "react-i18next"
import { getLogoByCardType } from "../../services/logoService"

export interface IA2APaymentTypeIconProps {
    paymentTypeData: IPaymentLinkSupportedPaymentType
}

export const CreditCardPaymentTypeIcon = ({ paymentTypeData }: IA2APaymentTypeIconProps) => {
    const { t } = useTranslation()
    if (paymentTypeData.paymentType !== "CreditCard") {
        return null
    }

    const supportedCardBrands = paymentTypeData.configuration.supportedCardBrands ?? []
    return (
        <div className="payment-credit-card-logos">
            {supportedCardBrands.map((card) => {
                return <img key={card} className="credit-card-logo" src={getLogoByCardType(card, true)}></img>
            })}
        </div>
    )
}

export default CreditCardPaymentTypeIcon
