import React from "react"
import { useTranslation } from "react-i18next"
import { TCurrency } from "../../type/paymentPage/IPaymentPageData"
import { payThunk, preparePaymentInSwiipeApiThunk } from "../../store/thunks/paymentThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { initiateApplePaySession } from "../../services/applePayService"
import { paymentPageReducerActions } from "../../store/reducers/paymentPageReducer"
import ApplePayLogo from "./assets/ApplePayLogo.svg"
import { removeSchemeFromHostName } from "../../util/webshopUtil"
import { getQueryValueFromCurrentUrl } from "../../util/urlUtil"
import ChangePaymentMethodButton from "../buttons/changePaymentMethod/ChangePaymentMethodButton"
import "./ApplePaySection.scss"

export interface IApplePayDataProps {
    amount: number
    currency: TCurrency
    country?: string
    hostName: string
    platformOrderId: string
}

export const ApplePaySection = ({ amount, currency, country, hostName, platformOrderId }: IApplePayDataProps) => {
    const token = getQueryValueFromCurrentUrl("token")
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const preparePayment = async () => {
        const applePayToken = await initiateApplePaySession(
            country || "DK",
            currency,
            t("applePay.applePayScreenLabel", {
                platformOrderId,
                hostName: removeSchemeFromHostName(hostName),
            }),
            amount.toString(),
            async (data: any) => {
                return await dispatch(preparePaymentInSwiipeApiThunk(token, "ApplePay", data))
            }
        )
        if (!applePayToken) {
            return
        }
        dispatch(paymentPageReducerActions.setApplePayToken(applePayToken))
    }

    return (
        <>
            <div className="apple-pay-section">
                <button
                    className="apple-pay-btn"
                    onClick={async () => {
                        await preparePayment()
                        dispatch(payThunk("ApplePay"))
                    }}
                >
                    <span className="apple-pay-btn__inside">
                        {t("applePay.payWith")} <img src={ApplePayLogo} />
                    </span>
                </button>
            </div>
            <ChangePaymentMethodButton paymentType="ApplePay" />
        </>
    )
}

export default ApplePaySection
