import i18next from "i18next"
import { getText } from "../locale/lang"

export interface ServerError {
    isFallbackError: boolean
    errorToAlert: string
    errorCode?: string
    errorMessage?: string
    rawError: string
    formErrors?: { [name: string]: string }
}

function findErrorObject(str: string) {
    if (!str) {
        return undefined
    }
    const startIndex = str.indexOf("{")
    const lastIndex = str.lastIndexOf("}")
    if (startIndex < 0 || lastIndex < 1) {
        return undefined
    }
    const subStr = str.slice(startIndex, lastIndex + 1)
    const parsed = JSON.parse(subStr.replace(/\\n/g, ""))
    return parsed?.message ? (parsed as { message: string; status?: number }) : undefined
}

export function parseServerError(error: any, customDefaultError?: string): ServerError {
    if (error.response && error.response.data) {
        const data = error.response.data
        const isHtmlErrorPage = typeof data === "string" && data.indexOf("<html") >= 0
        if (!isHtmlErrorPage) {
            const errorObj = findErrorObject(data)
            if (errorObj) {
                return {
                    isFallbackError: false,
                    errorToAlert: errorObj.message,
                    errorCode: (errorObj.status ?? 500) + "",
                    errorMessage: errorObj.message,
                    rawError: error + "",
                }
            }
        }
        if (typeof data === "string" && data.indexOf("-") > 0 && !isHtmlErrorPage) {
            const errorDataParts = data.split("-")
            const errorCode = errorDataParts[0]
            const errorMessage = errorDataParts[1]

            return {
                isFallbackError: false,
                errorToAlert: getErrorFromCode("errors." + errorDataParts[0]),
                errorCode,
                errorMessage,
                rawError: error + "",
            }
        }
    }
    // TODO: Check for form errors

    return {
        isFallbackError: !customDefaultError,
        errorToAlert: customDefaultError || getText("common.error"),
        rawError: error + "",
    }
}

function getErrorFromCode(errorCode: string) {
    const value = i18next.t(errorCode)
    if (errorCode === value) {
        // Not found
        return i18next.t("common.error")
    }
    return value
}
