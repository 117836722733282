import React from "react"
import cn from "classnames"
import "./ErrorTitleText.scss"

interface IErrorTitleTextProps {
    title: string
    text: string
}

const ErrorTitleText = ({ text, title }: IErrorTitleTextProps) => {
    return (
        <div className="error-title-text-container">
            <div className={cn("title")}>{title}</div>
            <div className={cn("text")}>{text}</div>
        </div>
    )
}

export default ErrorTitleText
