import React from "react"
import cn from "classnames"
import "./TitleText.scss"

interface ITitleTextProps {
    title: string
    text: string
}

const TitleText = ({ text, title }: ITitleTextProps) => {
    return (
        <div className="title-text-container">
            <div className={cn("title")}>{title}</div>
            <div className={cn("text")}>{text}</div>
        </div>
    )
}

export default TitleText
