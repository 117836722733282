import React from "react"
import { useTranslation } from "react-i18next"
import "./PaymentCancelledPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import TitleText from "../components/text/TitleText"
import FormFooter from "../components/form/footer/FormFooter"

interface IPaymentCancelledPageProps {}

const PaymentCancelledPage = ({}: IPaymentCancelledPageProps) => {
    const { t } = useTranslation()
    return (
        <PageWrapper narrow>
            <div id="payment-cancelled-page">
                <TitleText title={t("paymentCancelledPage.title")} text={t("paymentCancelledPage.text")} />
            </div>
            <FormFooter />
        </PageWrapper>
    )
}

export default PaymentCancelledPage
