import React from "react"
import { useTranslation } from "react-i18next"
import "./PaymentLinkCancelledPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import TitleText from "../components/text/TitleText"
import FormFooter from "../components/form/footer/FormFooter"

interface PpaymentLinkCancelledPageProps {}

const PaymentLinkCancelledPage = ({}: PpaymentLinkCancelledPageProps) => {
    const { t } = useTranslation()
    return (
        <PageWrapper narrow>
            <div id="payment-link-cancelled-page">
                <TitleText title={t("paymentLinkCancelledPage.title")} text={t("paymentLinkCancelledPage.text")} />
            </div>
            <FormFooter />
        </PageWrapper>
    )
}

export default PaymentLinkCancelledPage
