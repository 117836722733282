import React from "react"
import { useTranslation } from "react-i18next"

interface IReservationNoticeTextProps {
    className: string
}

const ReservationNoticeText = ({ className }: IReservationNoticeTextProps) => {
    const { t } = useTranslation()

    // For Polish and Swedish translations, the reservation notice text is outdated.
    return <span className={className}>{t("paymentMessage.reservation")}</span>
}

export default ReservationNoticeText
