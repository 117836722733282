import axios, { AxiosRequestConfig, AxiosResponse, AxiosPromise } from "axios"
import { parseServerError } from "./errorService"
import { HttpMethod } from "../type/request/HttpMethod"
import { settings } from "../clientAppSettings"
import { RequestConfig } from "./../store/thunks/serverThunks"
import { logInfo } from "./../util/logUtil"

export async function serverServiceExecuteRequest<T>(
    method: HttpMethod,
    url: string,
    config?: AxiosRequestConfig,
    requestConfig?: RequestConfig
): Promise<T> {
    const conf = config || {}
    try {
        let headers: any = conf.headers
        switch (method) {
            case HttpMethod.GET:
                break
            case HttpMethod.POST:
                headers = {
                    ...conf.headers,
                    "Content-Type": "application/json",
                }
                break
            case HttpMethod.PUT:
                headers = {
                    ...conf.headers,
                    "Content-Type": "application/json",
                }
                break
            case HttpMethod.DELETE:
                headers = {
                    ...conf.headers,
                    "Content-Type": "application/json",
                }
                break
            default:
                throw new Error("HttpMethod not supported: " + method)
        }
        if (settings.logServerRequests) {
            logInfo(method, url, conf.params, conf.data)
        }
        const response: AxiosResponse<T> = await axios({
            ...conf,
            method,
            headers,
            url,
        })
        if (!response) {
            throw new Error("No response")
        }

        const responseData = response.data as T
        if (settings.logServerResponses) {
            logInfo(method, url, responseData)
            logInfo(JSON.stringify(responseData))
        }
        return responseData
    } catch (error) {
        logInfo("Request failed:", url, conf, error)
        const serverError = parseServerError(error, requestConfig ? requestConfig.defaultErrorMessage : undefined)
        throw serverError
    }
}
