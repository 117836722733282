import { paymentPageReducer, PaymentPageState } from "./reducers/paymentPageReducer"
import { ModalState, modalReducer } from "./reducers/modalReducer"
import { combineReducers } from "redux"
import { HostedFieldsState, hostedFiledsReducer } from "./reducers/hostedFieldsReducer"

export interface StoreState {
    modal: ModalState
    paymentPage: PaymentPageState
    hostedFields: HostedFieldsState
}

export const createRootReducer = () =>
    combineReducers<StoreState>({
        modal: modalReducer,
        paymentPage: paymentPageReducer,
        hostedFields: hostedFiledsReducer,
    })
