import React from "react"
import { useTranslation } from "react-i18next"
import "./PaymentFailedPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import ErrorTitleText from "../components/text/ErrorTitleText"
import FormFooter from "../components/form/footer/FormFooter"

interface IPaymentFailedPageProps {}

const PaymentFailedPage = ({}: IPaymentFailedPageProps) => {
    const { t } = useTranslation()
    return (
        <PageWrapper narrow>
            <div id="payment-failed-page">
                <ErrorTitleText title={t("paymentFailedPage.title")} text={t("paymentFailedPage.text")} />
            </div>
            <FormFooter />
        </PageWrapper>
    )
}

export default PaymentFailedPage
