import { ThunkAction } from "redux-thunk"
import { StoreState } from "./../StoreState"
import { AnyAction } from "redux"
import { serverServiceExecuteRequest } from "../../services/serverService"
import { AxiosRequestConfig } from "axios"
import { HttpMethod } from "../../type/request/HttpMethod"
import { addModalThunk } from "./modalThunks"
import { navigationService } from "../../services/navigationService"

export interface RequestConfig {
    noErrorAlert?: boolean
    defaultErrorMessage?: string
    errorHandlers?: ErrorHandler[]
}

export interface ErrorHandler {
    errorCode: string
    handleError: (err: string) => Promise<boolean>
}

export const authenticatedRequestThunk =
    <T>(
        method: HttpMethod,
        url: string,
        axiosConfig?: AxiosRequestConfig,
        requestConfig?: RequestConfig
    ): ThunkAction<Promise<T>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const response = await dispatch(
            requestThunk<T>(
                method,
                url,
                {
                    ...axiosConfig,
                    withCredentials: true,
                },
                requestConfig
            )
        )
        return response
    }

export const requestThunk =
    <T>(
        method: HttpMethod,
        url: string,
        axiosConfig?: AxiosRequestConfig,
        requestConfig?: RequestConfig
    ): ThunkAction<Promise<T>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const response = await serverServiceExecuteRequest<T>(method, url, axiosConfig, requestConfig)
            return response
        } catch (error: any) {
            const noErrors = requestConfig && requestConfig.noErrorAlert
            if (!noErrors && !!error.errorToAlert) {
                if (error.isFallbackError) {
                    await dispatch(addModalThunk({ type: "error", errorMessage: error.errorToAlert }))
                } else {
                    const errorHandlers =
                        requestConfig && !!requestConfig.errorHandlers
                            ? requestConfig.errorHandlers.filter((h) => h.errorCode === error.errorCode)
                            : []

                    let errorHandled = false
                    for (let i = 0; i < errorHandlers.length; i++) {
                        const handler = errorHandlers[i]
                        if (!errorHandled) {
                            errorHandled = await handler.handleError(error.errorToAlert)
                        }
                    }
                    if (!errorHandled) {
                        await dispatch(addModalThunk({ type: "error", errorMessage: error.errorToAlert }))
                    }
                }
            }
            throw error
        }
    }
