import React, { useEffect, useMemo } from "react"
import "./BrandSelection.scss"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { paymentPageReducerActions, paymentPageSelectors } from "../../store/reducers/paymentPageReducer"
import { useDispatch } from "react-redux"
import cn from "classnames"
import {
    getBrandPreferencesFromLocalStorage,
    getDefaultBrandSelection,
    tryToSaveBrandSelection,
} from "../../services/brandPreferencesService"
import { TPaymentType } from "../../type/paymentPage/IPaymentPageData"

export interface IBrandSelectionProps {
    className?: string
    paymentType: TPaymentType
    addTopSpaceWhenForced?: boolean
}

export const BrandSelection = ({ className, paymentType, addTopSpaceWhenForced }: IBrandSelectionProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const paymentPageData = useSelector(paymentPageSelectors.data)
    const creditCardTypeData = (paymentPageData?.supportedPaymentTypes ?? []).find((d) => d.paymentType === "CreditCard")
    const shouldDefaultToDankort =
        creditCardTypeData?.paymentType === "CreditCard"
            ? creditCardTypeData.configuration.shouldDefaultBrandSelectionToDankort
            : undefined

    const brandSelection = useSelector(paymentPageSelectors.brandSelection)
    const brandSelectionWithFallback = brandSelection ?? getDefaultBrandSelection(shouldDefaultToDankort)
    const brandSelectionWasForcedFromFailedPayment = useSelector(paymentPageSelectors.brandSelectionWasForcedFromFailedPayment)
    const storedBrandSelectionData = useMemo(() => getBrandPreferencesFromLocalStorage(), [])
    useEffect(() => {
        if (brandSelectionWasForcedFromFailedPayment === undefined && storedBrandSelectionData?.wasForcedFromFailedPayment) {
            dispatch(paymentPageReducerActions.setBrandSelectionWasForced(storedBrandSelectionData.wasForcedFromFailedPayment))
        }
        if (brandSelection === undefined && storedBrandSelectionData?.brandPreferences) {
            dispatch(paymentPageReducerActions.setBrandSelection(getDefaultBrandSelection(shouldDefaultToDankort)))
        }
    }, [storedBrandSelectionData?.wasForcedFromFailedPayment])

    if (!creditCardTypeData || creditCardTypeData.paymentType !== "CreditCard") {
        return null
    }

    const showDankortSelection = creditCardTypeData.configuration.showDankortSelection ?? false
    const allowedPaymentTypes = creditCardTypeData.configuration.allowDankortSelectionForPaymentTypes
    if (!showDankortSelection || (allowedPaymentTypes && !allowedPaymentTypes.includes(paymentType))) {
        return null
    }

    return (
        <div
            className={cn(
                "brand-selection",
                brandSelectionWasForcedFromFailedPayment && "brand-selection--forced",
                addTopSpaceWhenForced && "brand-selection--force-top-space",
                className
            )}
        >
            <span className="brand-selection__description">
                {t("brandSelection.description", {
                    mixedCardBrand: "VISA/Dankort",
                    brand: brandSelectionWithFallback === "NonDankort" ? "VISA" : "Dankort",
                })}
            </span>{" "}
            <span
                className="brand-selection__link-text"
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const newBrandSelection = brandSelectionWithFallback === "NonDankort" ? "Dankort" : "NonDankort"
                    tryToSaveBrandSelection(paymentType, newBrandSelection, false)
                    dispatch(paymentPageReducerActions.setBrandSelection(newBrandSelection))
                    dispatch(paymentPageReducerActions.setBrandSelectionWasForced(false))
                }}
            >
                {t("brandSelection.linkText", {
                    brand: brandSelectionWithFallback === "NonDankort" ? "Dankort" : "VISA",
                })}
            </span>
        </div>
    )
}

export default BrandSelection
