import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { EBrandSelection, IPaymentPageData, TPaymentType } from "../../type/paymentPage/IPaymentPageData"
import { StoreState } from "../StoreState"
import { IPreparePaymentInSwiipeApiResponse } from "../thunks/paymentThunks"

export type PaymentPageState = Readonly<{
    data?: IPaymentPageData
    selectedPaymentType?: TPaymentType
    dataWasFetched: boolean
    brandSelection?: EBrandSelection
    brandSelectionWasForcedFromFailedPayment?: boolean

    preparePayment: {
        [key in TPaymentType]?: {
            response: IPreparePaymentInSwiipeApiResponse
        }
    }
    applePayToken?: string
}>
const initialState: PaymentPageState = {
    data: undefined,
    dataWasFetched: false,
    selectedPaymentType: undefined,
    preparePayment: {},
}

export const paymentPageReducerActions = {
    addPaymentPageData: (data?: IPaymentPageData) => createAction("APP/ADD_PAYMENT_PAGE_DATA", { data }),
    setSelectedPaymentType: (selectedPaymentType?: TPaymentType) =>
        createAction("APP/SET_SELECTED_PAYMENT_TYPE", { selectedPaymentType }),
    setBrandSelection: (brandSelection?: EBrandSelection) => createAction("APP/SET_BRAND_SELECTION", { brandSelection }),
    setBrandSelectionWasForced: (brandSelectionWasForcedFromFailedPayment?: boolean) =>
        createAction("APP/SET_BRAND_SELECTION_WAS_FORCED", { brandSelectionWasForcedFromFailedPayment }),
    setPreparePaymentResponse: (paymentType: TPaymentType, preparePaymentResponse: IPreparePaymentInSwiipeApiResponse) =>
        createAction("APP/SET_PREPARE_PAYMENT_TOKEN", { paymentType, preparePaymentResponse }),
    setApplePayToken: (applePayToken: string) => createAction("APP/SET_APPLE_PAY_TOKEN", { applePayToken }),
}

export type PaymentPageActions = ActionsUnion<typeof paymentPageReducerActions>

export const paymentPageReducer = (state: PaymentPageState = initialState, action: PaymentPageActions) => {
    switch (action.type) {
        case "APP/ADD_PAYMENT_PAGE_DATA": {
            return {
                ...state,
                data: action.data,
                dataWasFetched: true,
                selectedPaymentType: action.data?.showAlternativePaymentMethods
                    ? undefined
                    : action.data?.supportedPaymentTypes.find((type) => type.isDefault == true)?.paymentType,
            }
        }
        case "APP/SET_SELECTED_PAYMENT_TYPE": {
            return {
                ...state,
                selectedPaymentType: action.selectedPaymentType,
            }
        }
        case "APP/SET_BRAND_SELECTION": {
            return {
                ...state,
                brandSelection: action.brandSelection,
            }
        }
        case "APP/SET_BRAND_SELECTION_WAS_FORCED": {
            return {
                ...state,
                brandSelectionWasForcedFromFailedPayment: action.brandSelectionWasForcedFromFailedPayment,
            }
        }
        case "APP/SET_PREPARE_PAYMENT_TOKEN": {
            return {
                ...state,
                preparePayment: {
                    ...state.preparePayment,
                    [action.paymentType]: {
                        token: action.preparePaymentResponse,
                    },
                },
            }
        }
        case "APP/SET_APPLE_PAY_TOKEN": {
            return {
                ...state,
                applePayToken: action.applePayToken,
            }
        }
    }
    return state
}

export const paymentPageSelectors = {
    data: (state: StoreState) => state.paymentPage.data,
    dataWasFetched: (state: StoreState) => state.paymentPage.dataWasFetched,
    selectedPaymentType: (state: StoreState) => state.paymentPage.selectedPaymentType,
    brandSelection: (state: StoreState) => state.paymentPage.brandSelection,
    brandSelectionWasForcedFromFailedPayment: (state: StoreState) => state.paymentPage.brandSelectionWasForcedFromFailedPayment,
    preparePaymentApiResponse: (state: StoreState, paymentType: TPaymentType) =>
        state.paymentPage.preparePayment[paymentType]?.response,
    applePayToken: (state: StoreState) => state.paymentPage.applePayToken,
}
