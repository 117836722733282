import { EBrandSelection, TPaymentType } from "../type/paymentPage/IPaymentPageData"
import { addHours } from "../util/timeUtil"

export interface IStoredBrandPreferences {
    brandPreferences: string
    wasForcedFromFailedPayment: boolean
}

function saveBrandPreferencesToLocalStorage(data: IStoredBrandPreferences) {
    localStorage.setItem("brandPreferences", JSON.stringify({ ...data, time: new Date().getTime() }))
}

export function getBrandPreferencesFromLocalStorage(): IStoredBrandPreferences | undefined {
    const fromLocalStorage = localStorage.getItem("brandPreferences") ?? ""
    const parsed = fromLocalStorage ? (JSON.parse(fromLocalStorage) as IStoredBrandPreferences & { time: number }) : undefined
    if (!parsed) {
        return undefined
    }
    if (!parsed.time || addHours(new Date(), -24).getTime() > parsed.time) {
        return undefined
    }
    const { time, ...data } = parsed
    return data
}

export function tryToSaveBrandSelection(
    paymentType: TPaymentType,
    brandSelection: EBrandSelection | undefined,
    isProcessing: boolean
) {
    if (!brandSelection || (paymentType !== "CreditCard" && paymentType !== "MobilePayOnline")) {
        return
    }
    saveBrandPreferencesToLocalStorage({
        brandPreferences: brandSelection,
        wasForcedFromFailedPayment: paymentType === "MobilePayOnline" && brandSelection === "NonDankort" && isProcessing,
    })
}

export function getDefaultBrandSelection(shouldDefaultBrandSelectionToDankort: boolean | undefined): EBrandSelection {
    const data = getBrandPreferencesFromLocalStorage()
    if (!data) {
        return shouldDefaultBrandSelectionToDankort ? "Dankort" : "NonDankort"
    }
    if (data.wasForcedFromFailedPayment) {
        return "Dankort"
    }
    const fromPrefs = getBrandSelectionFromPreferences(data.brandPreferences)
    if (fromPrefs) {
        return fromPrefs
    }
    return shouldDefaultBrandSelectionToDankort ? "Dankort" : "NonDankort"
}

function getBrandSelectionFromPreferences(brandPreferences: string): EBrandSelection | undefined {
    const prefs = brandPreferences.split(",").map((p) => p.trim().toLowerCase())
    if (prefs.includes("nondankort")) {
        return "NonDankort"
    }
    if (prefs.includes("dankort")) {
        return "Dankort"
    }
    return undefined
}
